import React from 'react'
import Portfolio from "../components/Portfolio.js"
import SurveyMonkeyLogo from "../img/SurveyMonkeyLogo.svg"


export default function SurveyMonkey(){
  return(
    <>
   <Portfolio    
    pageTitle={<span><img width="500px" style={{"verticalAlign":"middle","marginTop":"-20px"}} src={SurveyMonkeyLogo}/> <span>+</span> John Dilworth</span>}
    pageIntro="I’m John Dilworth, and I would love to work with Survey Monkey as your Director of User Experience."
    pageIntroSize="1.5em"
    backSplash="#fff"
    backSplashAlt="#00bf6f"
    pageTitleColor="#000"
    pageTitleSize="3em"    
    introTextColor="#fff"
    introTextSize="1.5em"
    introTextLineHeight="1.25em"    
    textColor="#000"
    textColorAlt="#333e48"
    textSizeLarge="3em"
    textSizeMedium="1.5em"
    textSizeSmall="1em"
    textColor=""
    textLineHeight=""
    coverLetter={
      <div>
        <p>I have helped to hire, grow and manage 30+ person design teams. I directly oversaw a group of 20 designers, researchers, and prototypers (some remote, including myself).</p>        
        <p>I have worked closely with engineering and managed extensive design systems and front-end development teams.</p>
        <p>I love helping others do their best, and would like to bring my talent and experience to help make SurveyMonkey even better.</p>      
      </div>
    }
    coverBullets={
      <ul>
        <li>20+ years experience</li>
        <li>Nine years of experience in management roles up to 20 employees</li>
        <li>I know how to hire great talent</li>
        <li>Experience working in-house design and with a design agency</li>
        <li>6+ years successfully working as a remote employee</li>
        <li>Deep understanding of design process and software and mobile development</li>
        <li>I can design, code, write and am happy to do any of it</li>
        <li>I want to build amazing products and work with great people</li>        
      </ul>
    }
   />

     
   </>
  )
}


